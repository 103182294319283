import React from 'react';
import { Button } from 'react-bootstrap';

const ResultFilterText = ({ jobsCount, isWithActiveFilter, onClearFilter }) => {
  return (
    <div className="result-wrapper">
      {!isWithActiveFilter ? (
        <p>
          Currently displaying all <strong>{jobsCount}</strong> positions across
          all locations and departments.
        </p>
      ) : (
        <div className="result-text">
          <p>
            Currently displaying <strong>{jobsCount}</strong> positions with the
            above filters applied.
          </p>
          <Button type="button" variant="link" onClick={onClearFilter}>
            Clear all filters.
          </Button>
        </div>
      )}
    </div>
  );
};

export default ResultFilterText;
